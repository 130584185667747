import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { selectOpacity, setFocus } from "../wrappers/opacity-slice";

type Props = {};

export default function FocusModeToggle({}: Props) {
  const dispatch = useDispatch();
  const opacityFocused = useAppSelector(selectOpacity);
  return (
    <div className="gap-2 flex align-middle">
      <div className="my-auto">Focus Mode</div>
      <input
        type="checkbox"
        checked={opacityFocused}
        onChange={() => dispatch(setFocus(!opacityFocused))}
        className="accent-secondary h-4 w-4 my-auto"
      />
    </div>
  );
}
