import Image from "next/image";
import React from "react";
import { motion } from "framer-motion";

type Props = {};

export default function LoadingPlaceholder({}: Props) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="text-xl text-secondaryDark flex flex-col align-middle text-center m-4 dark:text-gray-300"
    >
      <div>
        <div className="animate-bounce">
          <Image src={"/logo-mobile.svg"} width={30} height={30} />
        </div>
      </div>
      <div className="transform hover:opacity-0 duration-500 select-none">
        loading
      </div>
    </motion.div>
  );
}
