import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import {
  selectCookiesAccepted,
  setCookiesAccepted,
} from "../wrappers/opacity-slice";
import Button from "./button";

type Props = {};

export default function CookieAggreement({}: Props) {
  const dispatch = useDispatch();
  const cookiesAccepted = useAppSelector(selectCookiesAccepted);
  if (!cookiesAccepted) {
    return (
      <div className="w-full fixed bottom-0 bg-primary dark:bg-gray-700 dark:text-gray-50 text-secondary border-2 border-secondary flex  justify-center p-6 gap-4">
        <div className="my-auto">
          We use cookies to make Koala Write better. 🍪{" "}
        </div>
        <Button
          small
          onClick={() => {
            dispatch(setCookiesAccepted(true));
          }}
        >
          <>I understand</>
        </Button>
      </div>
    );
  }
  return null;
}
