import { useSession } from "next-auth/react";
import React, { useEffect } from "react";
import TopBar from "../navigation/top-bar";
import { AnimatePresence, motion } from "framer-motion";
import DailyQuote from "../quotes/daily-quote";
import { useAppSelector } from "../../app/hooks";
import { selectOpacity } from "./opacity-slice";
import Head from "next/head";
import Button from "../elements/button";
import CookieAggreement from "../elements/cookie-agreement";
import { useRouter } from "next/router";

const PUBLIC_ROUTES = ["/auth", "/privacy-policy", "/terms-and-conditions"];

type Props = {
  children: JSX.Element;
};

const getQuote = async () => {
  await fetch("/api/quote").then((res) => res.json());
};

export default function Main({ children }: Props) {
  const router = useRouter();
  const { data: session } = useSession();
  const opacityFocused = useAppSelector(selectOpacity);
  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.documentElement.classList.add("dark");
    }
  }, []);
  const pageIsPublic =
    PUBLIC_ROUTES.find((route) => router.pathname.includes(route)) ||
    router.pathname === "/";
  return (
    <main
      className={
        "bg-primary dark:bg-gray-800 dark:text-white min-h-screen w-screen flex flex-col px-8 duration-700 "
      }
    >
      <Head>
        <title>Koala Write</title>
        <meta
          name="description"
          content="The platform for those who love writing."
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <AnimatePresence>
        <TopBar />
      </AnimatePresence>
      {session || pageIsPublic ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={
            "flex flex-col justify-center align-middle max-w-screen-md w-full mx-auto " +
            (opacityFocused ? "my-auto" : "")
          }
        >
          {children}
        </motion.div>
      ) : (
        <div className="flex flex-col justify-center align-middle max-w-screen-md w-full mx-auto gap-2">
          <div className="text-center font-bold text-xl text-secondaryDark dark:text-gray-200">
            Login to view this page 🐾
          </div>
          <Button onClick={() => (window.location.href = "/api/auth/signin")}>
            Login
          </Button>
        </div>
      )}
      <div className="mt-auto flex justify-center">
        {opacityFocused ? <div className="p-4 flex"></div> : <DailyQuote />}
        <CookieAggreement />
      </div>
    </main>
  );
}
