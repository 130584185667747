import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { selectAuthor, selectQuote, setQuote } from "./quote-slice";
import { motion } from "framer-motion";

type Props = {};

function QuoteBlock({ quote, author }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div>
        {quote}
        <div className="italic font-normal text-center">- {author}</div>
      </div>
    </motion.div>
  );
}
export default function DailyQuote({}: Props) {
  const getQuote = () => fetch("/api/quote").then((res) => res.json());
  const quote = useAppSelector(selectQuote);
  const author = useAppSelector(selectAuthor);
  const dispatch = useDispatch();
  const updateQuote = async () => {
    setRenderQuoteBlock(false);
    const quote = await getQuote();
    dispatch(setQuote(quote));
    setRenderQuoteBlock(true);
  };
  const [renderQuoteBlock, setRenderQuoteBlock] = useState(true);
  return (
    <div
      onClick={updateQuote}
      className="text-sm text-secondary font-semibold flex flex-col w-fit p-2 min-h-[5rem] justify-center text-center dark:text-gray-300"
    >
      {renderQuoteBlock ? <QuoteBlock quote={quote} author={author} /> : "..."}
    </div>
  );
}
