import React from "react";

type Props = {
  onClick?: any;
  children: JSX.Element | string;
  type?: "highlight";
  small?: boolean;
  active?: boolean;
  disabled?: boolean;
  clickable?: boolean;
  buttonType?: "button" | "submit" | "reset";
};

export default function Button({
  onClick,
  children,
  type,
  small = false,
  active = false,
  disabled = false,
  clickable = true,
  buttonType = "button",
}: Props) {
  const padding = small ? "p-1" : "p-4";
  const height = small ? "h-10" : "h-16";
  const activePadding = active ? "pt-1" : "";
  const activeBorder = active ? "" : "border-b-4";
  if (clickable) {
    return (
      <div
        className={
          height + " hover:pt-1 duration-200 transform " + activePadding
        }
      >
        {type ? (
          <button
            type={buttonType}
            disabled={disabled}
            className={
              "border-highlight" +
              " text-" +
              type +
              "  border-2 " +
              padding +
              " w-full rounded flex justify-center  font-bold " +
              activeBorder +
              " hover:border-b-2 duration-200 transform bg-primary  disabled:cursor-not-allowed dark:bg-gray-800 dark:border-gray-400 dark:text-gray-200"
            }
            onClick={onClick}
          >
            {children}
          </button>
        ) : (
          <button
            type={buttonType}
            disabled={disabled}
            className={
              "border-secondary border-2 " +
              padding +
              " w-full rounded flex justify-center text-secondary font-bold  " +
              activeBorder +
              " hover:border-b-2 duration-200 transform bg-primary  disabled:cursor-not-allowed dark:bg-gray-800 dark:border-gray-400 dark:text-gray-200"
            }
            onClick={onClick}
          >
            {children}
          </button>
        )}
      </div>
    );
  } else {
    return (
      <>
        {type ? (
          <button
            type={buttonType}
            disabled={disabled}
            className={
              "border-highlight" +
              " text-" +
              type +
              "  border-2 " +
              padding +
              " w-full rounded flex justify-center  font-bold border-b-4 bg-primary cursor-default  disabled:cursor-not-allowed dark:bg-gray-800 dark:border-gray-400 dark:text-gray-200"
            }
          >
            {children}
          </button>
        ) : (
          <button
            type={buttonType}
            disabled={disabled}
            className={
              "border-secondary border-2 " +
              padding +
              " w-full rounded flex justify-center text-secondary font-bold  border-b-4 bg-primary cursor-default  disabled:cursor-not-allowed dark:bg-gray-800 dark:border-gray-400 dark:text-gray-200"
            }
          >
            {children}
          </button>
        )}
      </>
    );
  }
}
