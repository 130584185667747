import "../styles/globals.css";
import { SessionProvider } from "next-auth/react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import LoadingPlaceholder from "../feature/elements/loading-placeholder";

import store, { persistor } from "../app/store";
import Main from "../feature/wrappers/main";
import PlausibleProvider from "next-plausible";

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  return (
    <>
      <PlausibleProvider
        domain="koalawrite.com"
        customDomain="https://plausible.vangucht.dev"
      >
        <Provider store={store}>
          <PersistGate loading={<LoadingPlaceholder />} persistor={persistor}>
            <SessionProvider session={session}>
              <Main>
                <Component {...pageProps} />
              </Main>
            </SessionProvider>
          </PersistGate>
        </Provider>
      </PlausibleProvider>
    </>
  );
}

export default MyApp;
