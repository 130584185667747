export const createDocument = async (): Promise<number> => {
  const id = await fetch("/api/document", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => data.id)
    .catch((e) => e);
  return id;
};
