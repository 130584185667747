import React from "react";
import { signOut } from "next-auth/react";
import { FaLeaf, FaPlus } from "react-icons/fa";
import FocusModeToggle from "../elements/focus-mode-toggle";
import Link from "next/link";
import { createDocument } from "../document/api";
import { useRouter } from "next/router";

export function SignedInMenu() {
  const router = useRouter();
  const handleCreateDocument = async () => {
    const documentId = await createDocument();
    router.push("/document/" + documentId);
  };

  return (
    <div className="group relative text-secondary my-auto pl-4 dark:text-gray-200">
      <FaLeaf size={25} />
      <div className="opacity-0 group-hover:opacity-100 scale-0 group-hover:scale-100 duration-500 origin-top-right z-50 absolute top-4 right-4 flex flex-col whitespace-nowrap bg-primary border-2 border-secondary rounded dark:border-gray-400 dark:bg-gray-800">
        <div className="flex justify-between px-4 py-2 hover:bg-secondary dark:hover:bg-gray-600 hover:text-primary font-bold">
          <Link href="/parties">
            <div className="cursor-pointer">My Parties</div>
          </Link>
          <Link href="/parties/new-party">
            <div className="my-auto">
              <FaPlus
                className="my-auto cursor-pointer text-highlight"
                size={15}
              />
            </div>
          </Link>
        </div>
        <div className="flex justify-between px-4 py-2 hover:bg-secondary dark:hover:bg-gray-600 hover:text-primary font-bold cursor-pointer">
          <Link href="/documents">My Documents</Link>
          <button
            onClick={() => {
              handleCreateDocument();
            }}
          >
            <FaPlus
              className="my-auto cursor-pointer ml-2 text-highlight"
              size={15}
            />
          </button>
        </div>
        <Link href="/myaccount">
          <div className="px-4 py-2 hover:bg-secondary dark:hover:bg-gray-600 hover:text-primary font-bold cursor-pointer">
            My Account
          </div>
        </Link>
        <div className="px-4 py-2 hover:bg-secondary dark:hover:bg-gray-600 hover:text-primary font-bold">
          <FocusModeToggle />
        </div>
        <button
          className="font-bold px-4 py-2 hover:bg-secondary dark:hover:bg-gray-600 hover:text-primary text-left"
          onClick={() => signOut()}
        >
          Sign out
        </button>
      </div>
    </div>
  );
}
