import React from "react";
import logo from "../../public/logo.svg";
import logomobile from "../../public/logo-mobile.svg";
import darkLogo from "../../public/logo-dark.svg";
import darkLogoMobile from "../../public/logo-dark-mobile.svg";
import Image from "next/image";
import { useSession, signIn } from "next-auth/react";
import { motion } from "framer-motion";
import Link from "next/link";
import { useAppSelector } from "../../app/hooks";
import { selectOpacity, selectShowDarkMode } from "../wrappers/opacity-slice";
import Button from "../elements/button";
import DarkModeToggle from "../elements/dark_mode_toggle";
import { SignedInMenu } from "./signed-in-menu";

export default function TopBar() {
  const { data: session } = useSession();
  const opacityFocused = useAppSelector(selectOpacity);
  const showDarkMode = useAppSelector(selectShowDarkMode);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex justify-between"
    >
      <Link href={session ? "/dashboard" : "/"}>
        <div>
          {!opacityFocused && (
            <>
              <div className="sm:block hidden">
                <Image
                  alt="Koala Write logo"
                  src={showDarkMode ? darkLogo : logo}
                  width={300}
                />
              </div>
              <div className="sm:hidden block">
                <Image
                  alt="Koala Write logo"
                  src={showDarkMode ? darkLogoMobile : logomobile}
                  width={50}
                />
              </div>
            </>
          )}
        </div>
      </Link>
      <div className="flex align-middle items-center">
        {session ? (
          <SignedInMenu />
        ) : (
          <div className="my-auto">
            <div>
              <Button small={true} onClick={() => signIn()}>
                <>Sign in</>
              </Button>
            </div>
          </div>
        )}
        <div className="px-4">
          <DarkModeToggle />
        </div>
      </div>
    </motion.div>
  );
}
