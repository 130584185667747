import React, { useEffect, useState } from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { selectShowDarkMode, setShowDarkMode } from "../wrappers/opacity-slice";

type Props = {};

export default function DarkModeToggle({}: Props) {
  const dispatch = useDispatch();
  const showDarkMode = useAppSelector(selectShowDarkMode);
  function setLightTheme() {
    dispatch(setShowDarkMode(false));
    setSelectedTheme("light");
    checkForToggle();
  }
  function setDarkTheme() {
    dispatch(setShowDarkMode(true));
    setSelectedTheme("dark");
    checkForToggle();
  }
  function checkForToggle() {
    if (showDarkMode) {
      document.documentElement.classList.add("dark");
      setSelectedTheme("dark");
    } else {
      document.documentElement.classList.remove("dark");
      setSelectedTheme("light");
    }
  }
  const [selectedTheme, setSelectedTheme] = useState("");
  useEffect(() => {
    checkForToggle();
  }, [showDarkMode]);
  return (
    <div className="flex gap-2 justify-center ">
      <button
        aria-label="Light Mode Toggle"
        onClick={setLightTheme}
        type="button"
        className={
          selectedTheme === "light"
            ? "text-yellow-500 dark:text-yellow-500"
            : ""
        }
      >
        <FaSun
          className=" duration-300 dark:text-gray-400 hover:text-yellow-500 hover:dark:text-yellow-500"
          size={"1em"}
        />
      </button>
      <button
        aria-label="Dark Mode Toggle"
        onClick={setDarkTheme}
        type="button"
        className={
          selectedTheme === "dark"
            ? "text-gray-200 dark:text-gray-200"
            : "text-secondary"
        }
      >
        <FaMoon
          className=" duration-300  dark:text-gray-200 hover:text-gray-200 hover:dark:text-gray-200"
          size={"1em"}
        />
      </button>
    </div>
  );
}
