import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { AppState } from "../../app/store";

export interface QuoteState {
  quote: string;
  author: string;
}

const initialState: QuoteState = {
  quote: "You can always edit a bad page. You can’t edit a blank page",
  author: "Jodi Picoult",
};

export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setQuote: (state, action: PayloadAction<any>) => {
      state.author = action.payload.author;
      state.quote = action.payload.quote;
    },
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthor = (state: AppState) => state.quote.author;
export const selectQuote = (state: AppState) => state.quote.quote;

export const { setQuote } = quoteSlice.actions;

export default quoteSlice.reducer;
