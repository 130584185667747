import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { AppState } from "../../app/store";

export interface OpacityState {
  focus: boolean;
  autosave: boolean;
  showDarkMode: boolean;
  cookiesAccepted: boolean;
}

const initialState: OpacityState = {
  focus: false,
  autosave: false,
  showDarkMode: false,
  cookiesAccepted: false,
};

export const opacitySlice = createSlice({
  name: "opacity",
  initialState,
  reducers: {
    setFocus: (state, action: PayloadAction<any>) => {
      state.focus = action.payload;
    },
    setAutosave: (state, action: PayloadAction<any>) => {
      state.autosave = action.payload;
    },
    setShowDarkMode: (state, action: PayloadAction<any>) => {
      state.showDarkMode = action.payload;
    },
    setCookiesAccepted: (state, action: PayloadAction<any>) => {
      state.cookiesAccepted = action.payload;
    },
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectOpacity = (state: AppState) => state.opacity.focus;
export const selectAutosave = (state: AppState) => state.opacity.autosave;
export const selectShowDarkMode = (state: AppState) =>
  state.opacity.showDarkMode;
export const selectCookiesAccepted = (state: AppState) =>
  state.opacity.cookiesAccepted;

export const { setFocus, setAutosave, setShowDarkMode, setCookiesAccepted } =
  opacitySlice.actions;

export default opacitySlice.reducer;
